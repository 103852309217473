import * as Sentry from '@sentry/browser';
import { AppConfig } from '$lib/stores/app-config';
import { defaultStackParser, makeFetchTransport } from '@sentry/browser';
import { BrowserTracing } from '@sentry/browser';
import debounce from 'just-debounce';
import { has } from '@temporalio/ui/utilities/has';

const dsn = import.meta.env?.VITE_SENTRY_DSN;
const username = import.meta.env?.VITE_SENTRY_USERNAME;

const debounceWarn = debounce(console.warn, 1000);

if (dsn) {
	Sentry.init({
		beforeSend(event, hint) {
			const error = hint.originalException;
			// We allow errors to prevent themselves from reporting by setting report to false in the error
			// this is used so we can prevent non usable errors going to sentry
			if (has(error, 'report') && error.report === false) {
				return null;
			}

			if (event?.message?.startsWith('Failed to fetch dynamically imported module:')) {
				event.fingerprint = ['vercel-cache-issue'];
			}

			return event;
		},
		dsn,
		release: import.meta.env.VITE_RELEASE_NAME,

		replaysSessionSampleRate: 0,
		replaysOnErrorSampleRate: 1.0,
		integrations: [
			new BrowserTracing({
				tracePropagationTargets: [],
				shouldCreateSpanForRequest: (url) => false
			}),
			new Sentry.Replay()
		],

		transport: makeFetchTransport,
		stackParser: defaultStackParser,

		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for performance monitoring.
		// We recommend adjusting this value in production
		tracesSampleRate: 0.5,

		environment: AppConfig.isProd ? 'production' : 'staging',
		denyUrls: [/events.launchdarkly.com/i],
		ignoreErrors: [
			/login required/i,
			/timeout/i,
			/RpcError: request unauthorized/i,
			/RpcError: user not found/i,
			'Invalid state',
			'Unable to preload',
			/Failed to fetch/i,
			'NetworkError',
			/^Not Found:/i
		]
	});
}

export const sentryCaptureException = (error: unknown): string | undefined => {
	if (dsn) {
		return Sentry.captureException(error, {
			user: {
				username
			}
		});
	}

	debounceWarn(new Error('Sentry is not configured'));
};
